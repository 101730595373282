// src/About.js

import React from 'react';

const About = () => {
  return (
    <div className="bg-white text-black py-8 md:py-16">
      <div className="max-w-5xl mx-auto px-4">
        {/* Main Heading */}
        <h2 className="text-4xl sm:text-5xl font-bold text-center mb-12 text-gray-900">
          About M2 InstaCount
        </h2>

        {/* Introduction */}
        <div className="mb-12">
          <p className="mb-6 text-lg sm:text-xl text-gray-700 leading-relaxed">
            M2 InstaCount is a powerful tool designed to revolutionize inventory management for Magento 2 stores. It addresses key pain points by enabling barcode-driven inventory checks and real-time discrepancy management—features not natively supported in Magento 2. This is particularly valuable for end-of-year and periodic inventory tasks, where accuracy and efficiency are crucial.
          </p>

          <p className="mb-6 text-lg sm:text-xl text-gray-700 leading-relaxed">
            One of the standout features of M2 InstaCount is its ability to make barcodes searchable attributes in Magento 2, allowing you to compare actual stock levels with expected values seamlessly. This makes it easier to identify and correct discrepancies before they become costly errors. The application also streamlines the order receiving process by scanning barcodes, fetching SKUs, and generating CSV files for easy comparison with supplier invoices.
          </p>

          <p className="mb-6 text-lg sm:text-xl text-gray-700 leading-relaxed">
            Whether you're managing a small or large Magento 2 store, M2 InstaCount simplifies your inventory processes, helping you maintain accuracy and efficiency year-round.
          </p>
        </div>

        {/* Upcoming Features */}
        <div className="bg-gray-100 p-8 rounded-lg shadow-md mb-12">
          <h3 className="text-2xl font-bold text-center mb-6 text-gray-900">Upcoming Features</h3>
          <ul className="list-disc list-inside ml-6 text-lg sm:text-xl text-gray-700 space-y-4">
            <li>
              <span className="font-semibold">Enhanced Handling of Purchased Items:</span> Improve tracking of items that have been purchased but not yet shipped, ensuring comprehensive inventory visibility.
            </li>
            <li>
              <span className="font-semibold">Direct Inventory Modification:</span> Modify inventory quantities directly within Magento when discrepancies are found, streamlining the correction process and reducing manual errors.
            </li>
          </ul>
        </div>

        {/* User Stories */}
        <div className="bg-gray-100 p-8 rounded-lg shadow-md mb-12">
          <h3 className="text-2xl font-bold text-center mb-6 text-gray-900">User Stories</h3>
          <div className="space-y-6">
            <blockquote className="italic text-lg sm:text-xl text-gray-700">
              "M2 InstaCount has transformed our year-end inventory process. The ability to search by barcode has saved us countless hours."
              <footer className="mt-2 text-gray-600">- Tyler, Operations Manager</footer>
            </blockquote>
            <blockquote className="italic text-lg sm:text-xl text-gray-700">
              "The real-time updates and discrepancy management features have made our inventory more accurate and reliable."
              <footer className="mt-2 text-gray-600">- Cam, Magento Store Owner</footer>
            </blockquote>
          </div>
        </div>

        {/* Dedicated Support */}
        <div className="bg-gray-100 p-8 rounded-lg shadow-md mb-12">
          <h3 className="text-2xl font-bold text-center mb-6 text-gray-900">Dedicated Support</h3>
          <p className="mb-4 text-lg sm:text-xl text-gray-700 leading-relaxed">
            Our dedicated support team is here to assist you with any issues or questions. We value user feedback and continuously work to improve M2 InstaCount to better meet your needs. Whether you need help with a specific use case or have suggestions for new features, we're here to help.
          </p>
          <p className="text-lg sm:text-xl text-gray-700 leading-relaxed">
            With M2 InstaCount, you're not just getting a tool; you're gaining a partner committed to your store's success.
          </p>
        </div>

        {/* Call to Action */}
        <div className="text-center p-8 sm:p-12 md:p-16 bg-gradient-to-r from-orange-400 to-orange-600 rounded-lg shadow-lg">
          <h3 className="text-2xl sm:text-3xl font-bold mb-4 text-white">Try M2 InstaCount</h3>
          <p className="text-lg sm:text-xl text-white mb-6">
            Start your two-week free trial today and see the difference M2 InstaCount can make in your inventory management.
          </p>
          <a
            href="/signup"
            className="bg-white text-orange-500 px-6 py-3 rounded-lg shadow-lg hover:bg-gray-100 transition duration-300 text-base sm:text-lg md:text-xl inline-block"
          >
            Get Started with M2 InstaCount
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
