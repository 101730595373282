import React from 'react';
import { Link } from 'react-router-dom';


const Guide = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50 p-4 sm:p-6 w-full">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-xl max-w-4xl w-full">
        <h2 className="text-4xl font-extrabold text-center text-black mb-8">Getting Started with M2 InstaCount</h2>
        
        <div className="p-6 sm:p-8 rounded-lg bg-gray-100 shadow-md mb-8">
          <p className="text-lg text-gray-800 mb-6">To streamline your inventory management, follow these two essential steps:</p>
          <ol className="list-decimal list-inside text-lg text-gray-800 ml-6 mb-8">
            <li className="mb-4">Configure Magento to integrate with our extension.</li>
            <li>Register your Magento credentials with our system.</li>
          </ol>
        </div>

        <div className="p-6 sm:p-8 rounded-lg bg-gray-100 shadow-md mb-8">
          <h3 className="text-3xl font-bold text-black mb-4">Step 1: Configuring Magento for Integration</h3>
          <ol className="list-decimal list-inside text-lg text-gray-800 ml-6 mb-6">
            <li className="mb-4">Access your Magento Admin Panel using your standard login credentials.</li>
            <li className="mb-4">In the sidebar, navigate to 'System'. Under 'Permissions', select 'All Users'.</li>
            <li className="mb-4">Create a new user and assign administrator rights. Note the username and password; these credentials will be used to connect Magento with our platform during setup.</li>
            <li className="mb-4">Create custom project attributes called "discrepancy_notes" and "discrepancy_exists". These will be used inside of search functionality to flag products as having a discrepancy and adding notes for products.</li>
            <li>You've completed the most challenging part. Proceed to finalize the setup to start utilizing M2 InstaCount.</li>
          </ol>
        </div>

        <div className="p-6 sm:p-8 rounded-lg bg-gray-100 shadow-md">
          <h3 className="text-3xl font-bold text-black mb-4">Step 2: Registering Your Magento Credentials</h3>
          <p className="text-lg text-gray-800 mb-6">Use the button below to add the newly created Magento account to our database, completing your setup.</p>
          <div className="text-center">
            <Link to="/setup" className="bg-orange-500 text-white px-6 py-3 rounded-lg hover:bg-orange-600 transition duration-300">Complete Setup</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guide;
