import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    if (!sessionId) {
      navigate('/login');
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/session-status?session_id=${sessionId}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          navigate('/login');
        } else {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
        }
      })
      .catch(() => {
        navigate('/login');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate]);

  useEffect(() => {
    if (status === 'complete') {
      // Staying on the return page
    } else if (status === 'open') {
      navigate('/payment');
    }
  }, [status, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-50 p-4 sm:p-6 space-y-6">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-xl w-full max-w-md text-center">
        {status === 'complete' ? (
          <>
            <h2 className="text-4xl font-extrabold mb-4 text-black">Thank You!</h2>
            <p className="text-gray-700 mb-4">We appreciate your business! A confirmation email will be sent to <span className="font-semibold text-black">{customerEmail}</span>.</p>
            <p className="text-gray-700">
              If you have any questions, please email <a href="support@m2instacount.com" className="text-orange-500 hover:underline">support@m2instacount.com</a>.
            </p>
          </>
        ) : (
          <p className="text-gray-700">Redirecting...</p>
        )}
      </div>
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-xl w-full max-w-md text-center">
        <h2 className="text-4xl font-extrabold mb-4 text-black">Next Steps</h2>
        <p className="text-gray-700 mb-4">If you haven't already, fill in your Magento credentials now.</p>
        <Link to="/guide" className="text-orange-500 hover:underline">Guide</Link>
      </div>
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-xl w-full max-w-md text-center">
        <h2 className="text-4xl font-extrabold mb-4 text-black">Start Searching</h2>
        <p className="text-gray-700 mb-4">If you've already linked your M2 Instacount and Magento, begin searching now.</p>
        <Link to="/search" className="text-orange-500 hover:underline">Begin Search</Link>
      </div>
    </div>
  );
};

export default Return;
