import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

// key reflects state of deployment
const stripePromise = loadStripe("pk_live_51MG754HEw37aaKue3nODgBHCWB56QoDvKwXqXVwX6B1lhwCC7WCIAAoYNYfuo4xMSGpTTTLCnBCzOvGjTdc0hm8E00trhGXHpF");

const Payment = () => {
  const location = useLocation();
  const { plan, email } = location.state || { plan: 'monthly', email: '' };
  const [clientSecret, setClientSecret] = useState("");

  const createCheckoutSession = useCallback(async (selectedPlan, email) => {
    const items = [
      { price: selectedPlan === "monthly" ? 'price_1Q10KOHEw37aaKueV11TL2DM' : 'price_1Q10JRHEw37aaKuewkuteTA3', quantity: 1 },
    ];

    try {
      const token = localStorage.getItem('access_token'); // Retrieve the token from local storage
      const response = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}` // Include the token in the request 
        },
        body: JSON.stringify({ items, email }), // Including email in the request body
      });

      const data = await response.json();
      if (response.ok) {
        setClientSecret(data.clientSecret);
      } else {
        setClientSecret('');
      }
    } catch {
      setClientSecret('');
    }
  }, []);

  useEffect(() => {
    createCheckoutSession(plan, email);
  }, [plan, email, createCheckoutSession]);

  const options = { clientSecret };

  return (
    <div id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default Payment;
