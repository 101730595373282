// src/CustomWebApp.js

import React from 'react';

const CustomWebApp = () => {
  return (
    <div className="bg-white text-black">
      {/* Services Section */}
      <section className="py-16 px-4 bg-gray-800 text-white">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-4xl sm:text-5xl font-bold mb-8">Our Services</h2>
          <div className="flex flex-wrap justify-center gap-8">
            {/* Service Card 1 */}
            <div className="w-full md:w-1/3 bg-gray-700 p-6 rounded-lg shadow-md hover:shadow-xl transition transform hover:-translate-y-1 duration-300">
              <h3 className="text-2xl font-semibold mb-2">Magento Customization</h3>
              <p className="text-gray-300">
                Enhance your Magento store with bespoke features and functionalities tailored to your business requirements.
              </p>
            </div>
            {/* Service Card 2 */}
            <div className="w-full md:w-1/3 bg-gray-700 p-6 rounded-lg shadow-md hover:shadow-xl transition transform hover:-translate-y-1 duration-300">
              <h3 className="text-2xl font-semibold mb-2">Full-Stack Development</h3>
              <p className="text-gray-300">
                From front-end interfaces to back-end databases, we deliver comprehensive web solutions that drive results.
              </p>
            </div>
            {/* Service Card 3 */}
            <div className="w-full md:w-1/3 bg-gray-700 p-6 rounded-lg shadow-md hover:shadow-xl transition transform hover:-translate-y-1 duration-300">
              <h3 className="text-2xl font-semibold mb-2">Responsive Design</h3>
              <p className="text-gray-300">
                Ensure your web applications look and perform flawlessly across all devices and screen sizes.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="py-16 px-4 bg-white">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-4xl sm:text-5xl font-bold mb-8 text-gray-800">Our Development Process</h2>
          <div className="flex flex-wrap justify-center gap-8">
            {/* Process Step 1 */}
            <div className="w-full md:w-1/4 bg-gray-100 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
              <h3 className="text-xl font-semibold mb-2 text-gray-800">Consultation</h3>
              <p className="text-gray-700">
                Understand your business needs and project requirements to lay a solid foundation.
              </p>
            </div>
            {/* Process Step 2 */}
            <div className="w-full md:w-1/4 bg-gray-100 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
              <h3 className="text-xl font-semibold mb-2 text-gray-800">Design</h3>
              <p className="text-gray-700">
                Create intuitive and visually appealing designs that enhance user experience.
              </p>
            </div>
            {/* Process Step 3 */}
            <div className="w-full md:w-1/4 bg-gray-100 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
              <h3 className="text-xl font-semibold mb-2 text-gray-800">Development</h3>
              <p className="text-gray-700">
                Build robust and scalable web applications using the latest technologies.
              </p>
            </div>
            {/* Process Step 4 */}
            <div className="w-full md:w-1/4 bg-gray-100 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
              <h3 className="text-xl font-semibold mb-2 text-gray-800">Deployment & Support</h3>
              <p className="text-gray-700">
                Launch your application and provide ongoing support to ensure optimal performance.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 px-4 bg-orange-500 text-white">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-6">
            Ready to Elevate Your Business with a Custom Web App?
          </h2>
          <p className="text-lg sm:text-xl mb-6">
            Contact us today to discuss your project and take the first step towards a tailored solution.
          </p>
          <a
            href="mailto:me@collinburleigh.com"
            className="bg-white text-orange-500 px-6 py-3 rounded-lg shadow-lg hover:bg-gray-100 transition duration-300 inline-block"
          >
            Get in Touch
          </a>
        </div>
      </section>
    </div>
  );
};

export default CustomWebApp;
