import { useState, useCallback } from 'react';

const useBarcodeHandler = () => {
  const [error, setError] = useState('');

  const handleScan = useCallback(async (scannedBarcode, setProduct, setLoading) => {
    if (!scannedBarcode) {
      setError('Please enter a valid barcode.');
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/search-product/barcode/${scannedBarcode}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      console.log('Received data:', data); // For debugging

      if (response.ok) {
        if (data.products && data.products.length > 0) {
          setProduct(data.products[0]); // Use the first product from the list
          setError('');
        } else {
          setError('Product not found');
        }
      } else {
        setError(data.error || 'Product not found');
      }
    } catch (error) {
      console.error('Error fetching product:', error);
      setError('Error fetching product');
    } finally {
      setLoading(false);
    }
  }, []);

  return { handleScan, error, setError };
};

export default useBarcodeHandler;
