// src/AuthContext.js

import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// Create the AuthContext
const AuthContext = createContext();

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  // State variables
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({
    credentialsFull: false,
    hasPaid: false,
    isTrialing: false,       // Initialize isTrialing
    trialEndDate: null       // Optional: Initialize trialEndDate if you plan to use it
  });
  const navigate = useNavigate();

  // Function to verify the JWT token and fetch user details
  const verifyToken = useCallback(async (token) => {
    try {
      // Verify the token
      const response = await fetch(`${process.env.REACT_APP_API_URL}/verify-token`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        // Token is valid
        setIsAuthenticated(true);

        // Fetch user details
        const detailsResponse = await fetch(`${process.env.REACT_APP_API_URL}/user-details`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (detailsResponse.ok) {
          const detailsData = await detailsResponse.json();
          console.log('Fetched User Details:', detailsData); // Debug: Check the fetched details

          // Update userDetails state
          setUserDetails({
            credentialsFull: detailsData.credentialsFull || false,
            hasPaid: detailsData.hasPaid || false,
            isTrialing: detailsData.isTrialing || false,
            trialEndDate: detailsData.trialEndDate || null, // Optional: Set trialEndDate if available
          });
        } else {
          console.error('Failed to fetch user details:', detailsResponse.statusText);
          // Optionally handle the error, e.g., show a notification
        }
      } else {
        // Token is invalid or expired
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        setUser(null);
        setIsAuthenticated(false);
        navigate('/login');
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
      setUser(null);
      setIsAuthenticated(false);
      navigate('/login');
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  // useEffect to check for stored user and token on component mount
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const accessToken = localStorage.getItem('access_token');

    if (storedUser && accessToken) {
      setUser(JSON.parse(storedUser));
      verifyToken(accessToken);
    } else {
      setLoading(false);
    }
  }, [verifyToken]);

  // Login function
  const login = (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('access_token', userData.access_token);
    setUser(userData);
    setIsAuthenticated(true);
    setLoading(false);
    navigate('/');
  };

  // Logout function
  const logout = async () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        setUser(null);
        setIsAuthenticated(false);
        setLoading(false);
        navigate('/login');
      } else {
        console.error('Failed to logout:', response.statusText);
        // Optionally handle the error, e.g., show a notification
      }
    } catch (error) {
      console.error('Error during logout:', error);
      // Optionally handle the error, e.g., show a notification
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated, loading, userDetails }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
