// src/Navigation.js

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from './AuthContext';
import logo from './M2-box-logo.png';

const Navigation = () => {
  const { user, logout, userDetails } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Helper function to determine if the user is active (paid or trialing)
  const isUserActive = () => {
    return userDetails.hasPaid || userDetails.isTrialing;
  };

  return (
    <nav className="bg-white p-4 shadow-md sticky top-0 z-50 w-full">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo and Home Link */}
        <Link to="/" className="flex items-center space-x-2">
          <img src={logo} alt="M2 InstaCount" className="h-12" />
        </Link>

        {/* Desktop Menu */}
        <div className="hidden md:flex space-x-6 items-center">
          {/* Display Payment or Trial Status */}
          {user && isUserActive() && (
            <span className="text-green-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">
              {userDetails.hasPaid ? 'Payment Valid' : 'Trial Active'}
            </span>
          )}

          {/* Display Credentials Status */}
          {user && userDetails.credentialsFull && (
            <span className="text-green-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300">
              Credentials Added
            </span>
          )}

          {/* Static Link */}
          <Link
            to="/about"
            className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300"
          >
            About
          </Link>

          {/* Conditional Links Based on Authentication */}
          {user ? (
            <>
              {/* Links for Users with Full Credentials and Active Subscription */}
              {userDetails.credentialsFull && isUserActive() && (
                <>
                  <Link
                    to="/search"
                    className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300"
                  >
                    Search
                  </Link>
                  <Link
                    to="/receiving"
                    className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300"
                  >
                    Receiving
                  </Link>
                </>
              )}

              {/* Links for Users Without Full Credentials */}
              {!userDetails.credentialsFull && (
                <>
                  <Link
                    to="/guide"
                    className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300"
                  >
                    Guide
                  </Link>
                  <Link
                    to="/setup"
                    className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300"
                  >
                    Setup
                  </Link>
                </>
              )}

              {/* Payment Link Only If Not Paid and Not Trialing */}
              {!userDetails.hasPaid && !userDetails.isTrialing && (
                <Link
                  to="/plan-selection"
                  className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300"
                >
                  Payment
                </Link>
              )}

              {/* Account and Logout Links */}
              <Link
                to="/account"
                className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300"
              >
                Account
              </Link>
              <button
                onClick={logout}
                className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300"
              >
                Logout
              </button>
            </>
          ) : (
            <>
              {/* Links for Non-Authenticated Users */}
              <Link
                to="/login"
                className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300"
              >
                Login
              </Link>
              <Link
                to="/signup"
                className="no-underline text-black hover:text-orange-500 px-4 py-2 rounded-md text-lg font-medium transition-colors duration-300"
              >
                Signup
              </Link>
            </>
          )}
        </div>

        {/* Mobile Menu Toggle Button */}
        <button
          className="md:hidden text-black focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* Hamburger Icon */}
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed inset-y-0 left-0 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out md:hidden bg-white p-6 shadow-lg w-64`}
      >
        {/* Close Button */}
        <button
          className="text-black focus:outline-none self-end mb-4"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* Close Icon */}
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        {/* Display Payment or Trial Status */}
        {user && isUserActive() && (
          <span className="block text-green-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">
            {userDetails.hasPaid ? 'Payment Valid' : 'Trial Active'}
          </span>
        )}

        {/* Display Credentials Status */}
        {user && userDetails.credentialsFull && (
          <span className="block text-green-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300">
            Credentials Added
          </span>
        )}

        <hr className="my-2 border-gray-300" />

        {/* Static Link */}
        <Link
          to="/about"
          className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300"
        >
          About
        </Link>

        {/* Conditional Links Based on Authentication */}
        {user ? (
          <>
            {/* Links for Users with Full Credentials and Active Subscription */}
            {userDetails.credentialsFull && isUserActive() && (
              <>
                <Link
                  to="/search"
                  className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300"
                >
                  Search
                </Link>
                <Link
                  to="/receiving"
                  className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300"
                >
                  Receiving
                </Link>
              </>
            )}

            {/* Links for Users Without Full Credentials */}
            {!userDetails.credentialsFull && (
              <>
                <Link
                  to="/guide"
                  className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300"
                >
                  Guide
                </Link>
                <Link
                  to="/setup"
                  className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300"
                >
                  Setup
                </Link>
              </>
            )}

            {/* Payment Link Only If Not Paid and Not Trialing */}
            {!userDetails.hasPaid && !userDetails.isTrialing && (
              <Link
                to="/plan-selection"
                className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300"
              >
                Payment
              </Link>
            )}

            {/* Account and Logout Links */}
            <Link
              to="/account"
              className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300"
            >
              Account
            </Link>
            <button
              onClick={logout}
              className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300"
            >
              Logout
            </button>
          </>
        ) : (
          <>
            {/* Links for Non-Authenticated Users */}
            <Link
              to="/login"
              className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300"
            >
              Login
            </Link>
            <Link
              to="/signup"
              className="block no-underline text-black hover:text-orange-500 w-full px-4 py-2 text-center rounded-md text-lg font-medium transition-colors duration-300"
            >
              Signup
            </Link>
          </>
        )}
      </div>

      {/* Optional: Display Trial End Notification */}
      {user && userDetails.isTrialing && userDetails.trialEndDate && (
        <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Trial Active!</strong>
          <span className="block sm:inline">
            Your trial will expire on {new Date(userDetails.trialEndDate * 1000).toLocaleDateString()}. Upgrade to continue using premium features.
          </span>
        </div>
      )}
    </nav>
  );
};

export default Navigation;
