import React from 'react';
import { Carousel } from 'react-bootstrap';

const Landing = () => {
  return (
    <div className="bg-white text-black">
      {/* Hero Section */}
      <div className="flex flex-col md:flex-row justify-between items-center p-12 bg-gray-800 text-white">
        <div className="flex flex-col justify-center items-start mb-8 md:mb-0">
          <h1 className="text-6xl font-extrabold mb-4">M2 InstaCount</h1>
          <p className="text-2xl mb-6">Transform your Magento 2 inventory management with barcode-driven accuracy and efficiency.</p>
        </div>
      </div>

      {/* Features Section */}
      <div className="text-center p-16">
        <h2 className="text-5xl font-bold mb-8 text-black">Why M2 InstaCount?</h2>
        <p className="text-xl mb-10">Streamline your inventory checks, catch discrepancies before they cost you, and receive orders more efficiently with our specialized tools.</p>
        <div className="flex flex-wrap justify-center gap-8">
          <div className="feature-item bg-gray-100 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 min-w-[250px] flex-1">
            <h3 className="text-xl font-semibold mb-2">Barcode-Driven Inventory Checks</h3>
            <p>Compare your actual stock levels with what's expected in Magento 2, using barcodes as searchable attributes—something Magento 2 doesn't natively support.</p>
          </div>
          <div className="feature-item bg-gray-100 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 min-w-[250px] flex-1">
            <h3 className="text-xl font-semibold mb-2">Real-Time Discrepancy Management</h3>
            <p>Identify and correct stock discrepancies in real-time, ensuring your inventory is accurate and up-to-date, especially during end-of-year counts.</p>
          </div>
          <div className="feature-item bg-gray-100 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 min-w-[250px] flex-1">
            <h3 className="text-xl font-semibold mb-2">Efficient Order Receiving</h3>
            <p>Speed up your receiving process with barcode scanning, automatically logging SKU counts, and generating a CSV file for seamless comparison with supplier invoices.</p>
          </div>
          <div className="feature-item bg-gray-100 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 min-w-[250px] flex-1">
            <h3 className="text-xl font-semibold mb-2">Periodic Inventory Management</h3>
            <p>Utilize our tools for regular inventory checks, helping you prevent costly errors by catching discrepancies before they escalate.</p>
          </div>
        </div>
      </div>

      {/* Demo Video Section */}
      <div className="text-center p-16 bg-gray-50">
        <h2 className="text-5xl font-bold mb-8 text-black">See M2 InstaCount in Action</h2>
        <div className="max-w-3xl mx-auto relative">
          <video
            controls
            className="w-full h-full rounded-lg shadow-md"
            style={{ border: 0 }}
          >
            <source src={require('./m2-vid.mp4')} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="text-center p-16 bg-gray-50">
        <h2 className="text-5xl font-bold mb-8 text-black">What Our Users Say</h2>
        <Carousel className="mx-auto w-full md:w-3/4 lg:w-1/2">
          <Carousel.Item>
            <p className="text-xl italic">"M2 InstaCount has made our year-end inventory process so much easier. Barcode search is a game-changer!"</p>
            <em className="block mt-4 text-gray-600">- Alex, E-commerce Manager</em>
          </Carousel.Item>
          <Carousel.Item>
            <p className="text-xl italic">"We've eliminated so many discrepancies thanks to the real-time updates. It’s exactly what we needed."</p>
            <em className="block mt-4 text-gray-600">- Jamie, Retail Supervisor</em>
          </Carousel.Item>
          <Carousel.Item>
            <p className="text-xl italic">"Receiving orders is now faster and more accurate. The CSV export feature saves us so much time."</p>
            <em className="block mt-4 text-gray-600">- Casey, Operations Director</em>
          </Carousel.Item>
        </Carousel>
      </div>

      {/* CTA Section */}
      <div className="text-center p-4 sm:p-8 md:p-16">
        <h2 className="text-2xl sm:text-3xl md:text-5xl font-bold mb-4 sm:mb-6 md:mb-8 text-black">
          Ready to Revolutionize Your Inventory Management?
        </h2>
        <p className="text-lg sm:text-xl md:text-2xl mb-6 text-gray-700">
          Start your two-week free trial today and experience the difference with M2 InstaCount.
        </p>
        <a href="/signup" className="bg-orange-500 text-white px-4 sm:px-6 md:px-8 py-2 sm:py-3 md:py-4 rounded-lg text-base sm:text-lg md:text-xl shadow-lg hover:bg-orange-600 transition duration-300 no-underline inline-block max-w-xs w-full sm:w-auto">
          Get Started with M2 InstaCount
        </a>
        <p className="text-sm text-gray-500 mt-4">
          Credit card required. Cancel anytime.
        </p>
      </div>
    </div>
  );
};

export default Landing;
