// Receiving.js

import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import { TailSpin } from 'react-loader-spinner';
import useBarcodeHandler from './useBarcodeHandler';

const Receiving = () => {
  const [barcode, setBarcode] = useState('');
  const [supplier, setSupplier] = useState('');
  const [scannedItems, setScannedItems] = useState([]);
  const [scanHistory, setScanHistory] = useState([]);
  const { handleScan, error, setError } = useBarcodeHandler();
  const [loading, setLoading] = useState(false);
  const [recentItem, setRecentItem] = useState(null);
  const [recentItemQuantity, setRecentItemQuantity] = useState(0);

  const handleReceivingScan = async (scannedBarcode) => {
    setLoading(true);
    await handleScan(
      scannedBarcode,
      (product) => {
        if (product) {
          const existingItem = scannedItems.find((item) => item.sku === product.sku);
          if (existingItem) {
            const updatedItems = scannedItems.map((item) =>
              item.sku === product.sku ? { ...item, quantity: item.quantity + 1 } : item
            );
            setScannedItems(updatedItems);
            setRecentItemQuantity(existingItem.quantity + 1);
          } else {
            const newItem = { ...product, quantity: 1, barcode: scannedBarcode };
            setScannedItems([...scannedItems, newItem]);
            setRecentItemQuantity(1);
          }
          setRecentItem({ ...product, barcode: scannedBarcode });
          setScanHistory([...scanHistory, scannedBarcode]);
        } else {
          // Handle case when product is not found
          const existingItem = scannedItems.find(
            (item) => item.barcode === scannedBarcode && !item.sku
          );
          if (existingItem) {
            const updatedItems = scannedItems.map((item) =>
              item.barcode === scannedBarcode && !item.sku
                ? { ...item, quantity: item.quantity + 1 }
                : item
            );
            setScannedItems(updatedItems);
            setRecentItemQuantity(existingItem.quantity + 1);
          } else {
            const newItem = { sku: '', name: '', quantity: 1, barcode: scannedBarcode };
            setScannedItems([...scannedItems, newItem]);
            setRecentItemQuantity(1);
          }
          setRecentItem({ sku: '', name: '', barcode: scannedBarcode });
          setScanHistory([...scanHistory, scannedBarcode]);
        }
        setLoading(false);
      },
      setLoading
    );
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setBarcode(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleReceivingScan(barcode);
      setBarcode(''); // Clear the input after scanning
    }
  };

  const handleUndo = () => {
    if (scanHistory.length === 0) return;

    const lastScannedBarcode = scanHistory[scanHistory.length - 1];
    const itemIndex = scannedItems.findIndex((item) => item.barcode === lastScannedBarcode);

    if (itemIndex !== -1) {
      const updatedItems = [...scannedItems];
      if (updatedItems[itemIndex].quantity > 1) {
        updatedItems[itemIndex].quantity -= 1;
        if (recentItem && recentItem.barcode === lastScannedBarcode) {
          setRecentItemQuantity(updatedItems[itemIndex].quantity);
        }
      } else {
        updatedItems.splice(itemIndex, 1);
        if (recentItem && recentItem.barcode === lastScannedBarcode) {
          setRecentItemQuantity(0);
        }
      }
      setScannedItems(updatedItems);
      setScanHistory(scanHistory.slice(0, -1));
    }
  };

  const handleExport = () => {
    const date = new Date().toLocaleDateString().replace(/\//g, '_');
    const time = new Date().toLocaleTimeString().replace(/:/g, '_');
    const title = `Scanned Items - ${date} ${time}${supplier ? ` - Supplier: ${supplier}` : ''}`;

    const csvHeader = `${title}\nBarcode,SKU,Name,Quantity\n`;
    const csvRows = scannedItems
      .map((item) => {
        const formattedBarcode = item.barcode; // No quotes
        const formattedSKU = item.sku;         // No quotes
        const formattedName = item.name;       // No quotes
        const formattedQuantity = item.quantity; // Quantity is a number

        return `${formattedBarcode},${formattedSKU},${formattedName},${formattedQuantity}`;
      })
      .join('\n');

    const csvContent = 'data:text/csv;charset=utf-8,' + csvHeader + csvRows;

    const blob = new Blob([csvHeader + csvRows], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `scanned_items_${supplier}_${date}_${time}.csv`);
  };

  const totalQuantity = scannedItems.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Receiving</h1>
      <div className="mb-6">
        <input
          type="text"
          value={supplier}
          onChange={(e) => setSupplier(e.target.value)}
          placeholder="Enter supplier name (optional)"
          className="p-4 border rounded w-full mb-4"
        />
        <input
          type="text"
          value={barcode}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="Scan or enter barcode"
          className="p-4 border rounded w-full"
        />
        {/* UI message to inform users to press  Enter */}
        <p className="text-gray-600 mt-2">
          Press <strong>Enter</strong> after typing to search if you are not using a barcode scanner.
        </p>
        {error && <p className="text-red-500 mt-2">{error}</p>}
        {loading && (
          <div className="flex justify-center items-center mt-2">
            <TailSpin color="#00BFFF" height={40} width={40} />
          </div>
        )}
        <div className="flex space-x-4 mt-4">
          <button
            onClick={handleUndo}
            className="bg-red-500 text-white px-6 py-2 rounded hover:bg-red-600"
          >
            Undo Last Scan
          </button>
        </div>
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-4">Summary</h2>
        <p className="text-xl">Total quantity of items scanned: {totalQuantity}</p>
        {recentItem && (
          <div className="mt-4 p-4 border rounded-lg bg-gray-100">
            <h3 className="text-xl font-bold mb-2">Most Recently Scanned Item</h3>
            <p>
              <strong>Barcode:</strong> {recentItem.barcode}
            </p>
            <p>
              <strong>SKU:</strong> {recentItem.sku}
            </p>
            <p>
              <strong>Name:</strong> {recentItem.name}
            </p>
            <p>
              <strong>Quantity:</strong> {recentItemQuantity}
            </p>
          </div>
        )}
      </div>
      <button
        onClick={handleExport}
        className="mt-8 bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600"
      >
        Export to CSV
      </button>
    </div>
  );
};

export default Receiving;
