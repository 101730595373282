import React, { useState, useCallback } from 'react';
import { TailSpin } from 'react-loader-spinner';

const Search = () => {
  const [search, setSearch] = useState('');
  const [product, setProduct] = useState(null);
  const [message, setMessage] = useState('');
  const [searchType, setSearchType] = useState('barcode'); // Default search type is 'barcode'
  const [condition, setCondition] = useState('new');       // Default condition is 'new'
  const [discrepancyNote, setDiscrepancyNote] = useState('');
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchProduct = useCallback(async () => {
    if (!search) {
      setError('Search term is empty');
      setLoading(false);
      return;
    }

    setProduct(null);
    setMessage('');
    setError(null);
    setLoading(true);

    const token = localStorage.getItem('access_token');
    if (!token) {
      setError('User is not authenticated');
      setLoading(false);
      return;
    }

    const endpoint = `${process.env.REACT_APP_API_URL}/search-product/${searchType}/${search}?condition=${condition}`;

    try {
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to fetch product');
        setLoading(false);
        return;
      }

      const data = await response.json();
      console.log('Received data:', data); // Debugging statement

      if (data.error) {
        setError(data.error);
      } else {
        if (data.products && data.products.length > 0) {
          setProduct(data.products[0]); // Use the first product from the list
        } else {
          setError('No products found.');
        }

        if (data.message) {
          setMessage(data.message);
        }
      }
    } catch (err) {
      setError('An error occurred while fetching product details.');
    } finally {
      setLoading(false);
    }
  }, [search, searchType, condition]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchProduct();
      setSearch('');
    }
  };

  const handleDiscrepancy = async () => {
    if (!product || !product.sku) return;

    const token = localStorage.getItem('access_token');
    if (!token) {
      setError('User is not authenticated');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/update-discrepancy/${product.sku}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      const result = await response.json();
      if (result.error) {
        setError(result.error);
      } else {
        alert('Discrepancy reported successfully!');
      }
    } catch (err) {
      setError('An error occurred while reporting the discrepancy.');
    }
  };

  const handleDiscrepancyNoteUpdate = async () => {
    if (!product || !product.sku) return;

    const token = localStorage.getItem('access_token');
    if (!token) {
      setError('User is not authenticated');
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/update-discrepancy-note/${product.sku}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ note: discrepancyNote }),
        }
      );
      const result = await response.json();
      if (result.error) {
        setError(result.error);
      } else {
        alert('Discrepancy note updated successfully!');
        setDiscrepancyNote('');
      }
    } catch (err) {
      setError('An error occurred while updating the discrepancy note.');
    }
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <div className="max-w-screen-lg mx-auto p-4">
      {/* Error Message */}
      {error && (
        <div className="bg-red-500 text-white p-4 rounded mb-4">{error}</div>
      )}

      {/* Search Type and Condition Selection */}
      <div className="flex flex-wrap space-x-4 mb-4">
        {/* Search Type Buttons */}
        <div className="flex space-x-2 mb-2">
          <button
            className={`px-4 py-2 rounded ${
              searchType === 'sku' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => setSearchType('sku')}
          >
            SKU
          </button>
          <button
            className={`px-4 py-2 rounded ${
              searchType === 'barcode' ? 'bg-orange-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => setSearchType('barcode')}
          >
            Barcode
          </button>
        </div>

        {/* Condition Buttons */}
        <div className="flex space-x-2 mb-2">
          <button
            className={`px-4 py-2 rounded ${
              condition === 'new' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => setCondition('new')}
          >
            New
          </button>
          <button
            className={`px-4 py-2 rounded ${
              condition === 'used' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'
            }`}
            onClick={() => setCondition('used')}
          >
            Used
          </button>
        </div>
      </div>

      {/* Search Input */}
      <div className="mb-4">
        <div>
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Enter {searchType.toUpperCase()}
          </label>
          <div className="relative">
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded"
              placeholder={`Input ${searchType}`}
              value={search}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              required
            />
            {search && (
              <button
                type="button"
                className="absolute top-0 right-0 p-2 bg-transparent border-none cursor-pointer"
                onClick={() => {
                  setSearch('');
                  setProduct(null);
                  setMessage('');
                  setError(null);
                }}
              >
                ♻️
              </button>
            )}
          </div>
          {/* UI message to inform users to press Enter */}
          <p className="text-gray-600 mt-2">
            Press <strong>Enter</strong> after typing to search if you are not using a barcode scanner.
          </p>
        </div>
      </div>

      {/* Loading Indicator */}
      {loading && (
        <div className="flex justify-center items-center">
          <TailSpin color="#00BFFF" height={80} width={80} />
        </div>
      )}

      {/* Message from the backend */}
      {message && (
        <div className="bg-yellow-200 text-yellow-800 p-4 rounded mb-4">{message}</div>
      )}

      {/* Product Display */}
      {product && !loading && (
        <div className="flex flex-col items-center space-y-4">
          <p className="text-2xl font-bold mb-4">
            <strong>Quantity:</strong> <span>{product.quantity}</span>
          </p>

          {product.image_url && (
            <img
              src={product.image_url}
              alt={product.name}
              className="w-64 h-64 object-contain mb-4"
            />
          )}

          <p className="text-xl font-bold mb-2">
            <strong>Name:</strong> {product.name}
          </p>
          <p className="text-lg mb-2">
            <strong>Description:</strong>{' '}
            {showFullDescription || !product.description
              ? product.description
              : `${product.description.slice(0, 100)}...`}
          </p>
          {product.description && product.description.length > 100 && (
            <button
              className="bg-white text-black border border-gray-300 px-4 py-2 rounded hover:shadow-lg transition duration-300"
              onClick={toggleDescription}
            >
              {showFullDescription ? 'Read Less' : 'Read More'}
            </button>
          )}

          {/* Discrepancy Reporting  */}
          <button
            className="bg-white text-black border border-gray-300 px-4 py-2 rounded hover:shadow-lg transition duration-300 mb-4 w-full"
            onClick={handleDiscrepancy}
          >
            Report a Discrepancy
          </button>
          <textarea
            placeholder="Enter discrepancy notes here..."
            value={discrepancyNote}
            onChange={(e) => setDiscrepancyNote(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-4"
          />
          <button
            className="bg-white text-black border border-gray-300 px-4 py-2 rounded hover:shadow-lg transition duration-300 w-full"
            onClick={handleDiscrepancyNoteUpdate}
          >
            Update Discrepancy Note
          </button>
        </div>
      )}

      {error && <div className="text-red-500 mt-4 text-center">{error}</div>}
    </div>
  );
};

export default Search;
