import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Account = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      const token = localStorage.getItem('access_token');
      if (!token) return;

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/subscription-details`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setSubscriptionDetails(data);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionDetails();
  }, []);

  const handleEmailClick = () => {
    const email = "support@m2instacount.com";
    const subject = encodeURIComponent("Subscription Cancellation Request");
    const body = encodeURIComponent("Please provide a brief note about why you are cancelling:");
    window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
  };

  const renderSubscriptionDetails = () => {
    const status = subscriptionDetails?.status?.toUpperCase() || "null - send us an email";
    const periodEnd = subscriptionDetails?.period_end 
      ? new Date(subscriptionDetails.period_end * 1000).toLocaleDateString() 
      : "null - send us an email";

    return (
      <>
        <div className="p-6 sm:p-8 rounded-lg bg-gray-100 shadow-md mb-6">
          <h3 className="text-3xl font-bold text-black mb-4">Subscription Details</h3>
          <p className="text-gray-800 text-lg mb-2"><strong>Status:</strong> {status}</p>
          <p className="text-gray-800 text-lg"><strong>Ends On:</strong> {periodEnd}</p>
        </div>
        <div className="p-6 sm:p-8 rounded-lg bg-gray-100 shadow-md mb-6">
          <h3 className="text-3xl font-bold text-black mb-4">Magento Credentials Update Instructions</h3>
          <p className="text-gray-800 mb-4">To update your Magento credentials, please follow these steps:</p>
          <ol className="list-decimal list-inside text-gray-800 text-lg ml-6">
            <li className="mb-3">Go to the <Link to="/setup" className="text-orange-500 hover:underline">Setup Page</Link>.</li>
            <li className="mb-3">Enter your new credentials. Make sure to fill out all fields.</li>
            <li>Click on the "Connect" button to save your new credentials.</li>
          </ol>
        </div>
        <div className="p-6 sm:p-8 rounded-lg bg-gray-100 shadow-md">
          <h3 className="text-3xl font-bold text-black mb-4">Cancel Your Subscription</h3>
          <p className="text-gray-800 mb-4">If you wish to cancel your subscription, please send us an email with a brief note about why you are cancelling:</p>
          <button 
            onClick={handleEmailClick} 
            className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 transition duration-300 w-full sm:w-auto"
          >
            Send Cancellation Email
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50 p-4 sm:p-6 w-full">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-xl w-full max-w-3xl mx-auto">
        <h2 className="text-4xl font-extrabold mb-6 text-center text-black">Account Details</h2>
        {loading ? (
          <p className="text-center text-gray-600">Loading subscription details...</p>
        ) : (
          renderSubscriptionDetails()
        )}
      </div>
    </div>
  );
};

export default Account;
