import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const Setup = () => {
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [magentoLogin, setMagentoLogin] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [credentialsExist, setCredentialsExist] = useState(false);
  const navigate = useNavigate();

  const checkCredentials = useCallback(async () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      setError('User is not authenticated');
      navigate('/login');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/magento-credentials`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });

      if (response.ok) {
        setCredentialsExist(true);
      } else {
        setCredentialsExist(false);
      }
    } catch (error) {
      setError('Error checking credentials');
    }
  }, [navigate]);

  useEffect(() => {
    checkCredentials();
  }, [checkCredentials]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    const formData = {
      magento_website_url: websiteUrl,
      magento_login: magentoLogin,
      magento_password: password
    };

    const token = localStorage.getItem('access_token');
    if (!token) {
      setError('User is not authenticated');
      navigate('/login');
      return;
    }

    try {
      const method = credentialsExist ? 'PUT' : 'POST';
      const response = await fetch(`${process.env.REACT_APP_API_URL}/magento-credentials`, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setSuccess('Credentials saved successfully!');
      navigate('/search');
    } catch (error) {
      setError('There was a problem with the fetch operation: ' + error.message);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50 p-4">
      <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-lg">
        <h2 className="text-4xl font-extrabold text-center mb-4">Connect Your Magento to M2 InstaCount</h2>
        <p className="text-center mb-4">Provide your M2 Credentials below:</p>
        <div className="text-center mb-4">
          <a href='/guide' className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 transition duration-300">Guide</a>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Magento Website URL</label>
            <input
              type="url"
              placeholder="https://www.your-magento-site.com"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
              required
              className="w-full p-3 border border-gray-300 rounded"
            />
            <small className="text-gray-600">Please enter a valid URL, including the protocol (http:// or https://).</small>
            <br></br>
            <br></br>
            <small className="text-gray-600">Make sure that there is no trailing forward slash at the end of your domain (/)</small>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Magento Login</label>
            <input
              type="text"
              placeholder="Enter login"
              value={magentoLogin}
              onChange={(e) => setMagentoLogin(e.target.value)}
              required
              className="w-full p-3 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Magento Password</label>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-3 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">Confirm Password</label>
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="w-full p-3 border border-gray-300 rounded"
            />
          </div>
          {error && <div className="text-red-500 mb-4">{error}</div>}
          {success && <div className="text-green-500 mb-4">{success}</div>}
          <button type="submit" className="bg-orange-500 text-white px-4 py-2 rounded w-full hover:bg-orange-600 transition duration-300">Connect</button>
        </form>
      </div>
    </div>
  );
}

export default Setup;
